import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import withClassname from '@helpers/hocs/withClassname';
import useOutsideClick from '@helpers/hooks/useOutsideClick';

import CancelAutosubPopup from '../CancelAutosubPopup';
import RoundButton from '../RoundButton';
import UserMenu from '../UserMenu';
import SertificatePopup from '../SertificatePopup';

import './style.scss';

const AvatarDialog = ({ userAvatarUrl, size, userEmail, className, full, showAutosubPopup, showSertificatePopup }) => {
  const ref = useRef();
  const [isOpen, setOpen] = useState(false);
  useOutsideClick(ref, () => setOpen(false));

  useEffect(() => {
    if (showAutosubPopup || showSertificatePopup) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [showAutosubPopup, showSertificatePopup]);

  return (
    <div className={`pst-avatarDialog ${className}`} ref={ref}>
      <RoundButton
        size={size}
        template='with-background'
        onClick={() => setOpen(!isOpen)}
        background={userAvatarUrl}
        name={isOpen ? 'Close' : 'Burger'}
        iconTempl='white'
      />
      {isOpen && (
        <div className="pst-avatarDialog__menu">
          <UserMenu full={full} userEmail={userEmail} handleClose={() => setOpen(false)} />
        </div>
      )}
      {showAutosubPopup && <CancelAutosubPopup />}
      {showSertificatePopup && <SertificatePopup />}
    </div>
  );
};

const mapStateToProps = ({ subscriptions, sertificate }) => ({
  showAutosubPopup: subscriptions.showCancelAutosubPopup,
  showSertificatePopup: sertificate.showSertificatePopup,
});

export default connect(mapStateToProps)(withClassname(AvatarDialog));
