import React from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-shadow,no-unused-vars
function RecommendationContent({ optionsMap, fixed, selected, handleSubmit }) {
  const { t } = useTranslation();
  const options = Object.keys(optionsMap).filter(k => selected.includes(k)).map(k => optionsMap[k]);
  return (
    <div className={`pst-problemQuiz ${fixed ? 'pst-problemQuiz--fixed' : ''}`}>
      <div className='pst-problemQuiz__content'>
        <div className='pst-problemQuiz__main'>
          <div className='pst-problemQuiz__title'>
            {t('recomendation.title')}
          </div>
          <div className='pst-problemQuiz__description'>
            {t('recomendation.description')}
          </div>
        </div>
      </div>

      <div className='pst-problemQuiz__optionsWrapper'>
        {options.map(item => <div className={'pst-problemQuiz__optionButton'}>
          {item}
        </div>)}
      </div>

      <div className={'pst-problemQuiz__submitButton'} onClick={handleSubmit}>
        {t('recomendation.submit')}
      </div>
    </div>
  );
}

export default RecommendationContent;
