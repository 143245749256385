import WsService from './WsService';
import { fetchAudio, fetchAudios, fetchVariable } from './TtsAudioService';
import getNameDeclension from '../helpers/getNameDeclension';

export const ttsDefaultOptions = {
  voice: 'Alexander',
  numChannels: 1,
  sampleRate: 22050,
  play: false,
};

class TtsStreamService {
  constructor(options = {}) {
    this.options = {
      voice: options.voice || ttsDefaultOptions.voice,
      sampleRate: options.sampleRate || ttsDefaultOptions.sampleRate,
      numChannels: ttsDefaultOptions.numChannels,
      play: false,
    };
    this.audioUrls = [];
    this.audios = [];
    this.currentAudioIndex = 0;
    this.ws = new WsService();
    this.onAudioPaused = () => {
      // eslint-disable-next-line no-console
      console.log('Audio paused');
    };
    this.onAudioPlaying = () => {
      // eslint-disable-next-line no-console
      console.log('Audio playing');
    };
    this.onFinishLoading = () => {
      // eslint-disable-next-line no-console
      console.log('Finish loading');
    };
    this.audiosEnded = () => {
      // eslint-disable-next-line no-console
      console.log('Audios ended');
    };
  }

  playAudio() {
    // eslint-disable-next-line no-console
    console.log(`playAudio ${this.currentAudioIndex + 1}`);
    this.play = true;
    const audio = this.audios[this.currentAudioIndex];
    if (audio && !audio.playing) {
      return audio.play()
      // eslint-disable-next-line no-console
        .catch(err => console.error(err));
    }
  }

  pauseAudio() {
    // eslint-disable-next-line no-console
    console.log('pauseAudio');
    this.play = false;
    const audio = this.audios[this.currentAudioIndex];
    if (audio && audio.playing) {
      audio.pause();
    }
  }

  clearAudios() {
    return new Promise((resolve, reject) => {
      try {
        // eslint-disable-next-line no-console
        console.log('clearAudios');
        this.currentAudioIndex = 0;
        this.audioUrls.map(url => URL.revokeObjectURL(url));
        this.audios.map(audio => audio.pause());
        this.audioUrls = [];
        this.audios = [];
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  clearState() {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-console
      console.log('clearState');
      if (!this.ws) return resolve(true);

      this.ws.setInterrupted(true);
      return this.ws.destroySocket()
        .then(() => this.clearAudios()
          .then(() => {
            this.ws = undefined;
            return resolve(this.onFinishLoading(false));
          })
          // eslint-disable-next-line no-console
          .catch(err => reject(err))
          // eslint-disable-next-line no-console
          .catch(err => reject(err)));
    });
  }

  encodeWav(int16Array) {
    const buffer = new ArrayBuffer(44 + int16Array.byteLength * 2);
    const view = new DataView(buffer);
    this.writeUTFBytes(view, 0, 'RIFF');
    view.setUint32(4, 44 + int16Array.length * 2, true);
    this.writeUTFBytes(view, 8, 'WAVE');
    this.writeUTFBytes(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, this.options.numChannels, true);
    view.setUint32(24, this.options.sampleRate, true);
    view.setUint32(28, this.options.sampleRate * 4, true);
    view.setUint16(32, 4, true);
    view.setUint16(34, 16, true);
    this.writeUTFBytes(view, 36, 'data');
    view.setUint32(40, int16Array.length * 2, true);

    const lng = int16Array.length;
    const volume = 1;

    let index = 44;

    for (let i = 0; i < lng; i++) {
      view.setInt16(index, int16Array[i] * volume, true);
      index += 2;
    }

    return new Blob([view], {
      type: 'audio/x-wav',
    });
  }

  // eslint-disable-next-line class-methods-use-this
  writeUTFBytes(view, offset, string) {
    const lng = string.length;
    for (let i = 0; i < lng; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  appendAudio(int16Array) {
    // eslint-disable-next-line no-console
    console.log('appendAudio');
    const blob = this.encodeWav(int16Array);
    const blobUrl = URL.createObjectURL(blob);
    this.audioUrls.push(blobUrl);

    const audio = new Audio(blobUrl);
    audio.onloadeddata = () => {
      audio.currentTime = 0.002;
    };

    audio.onpause = (event) => {
      audio.playing = false;
      this.onAudioPaused(event);
    };

    audio.onplay = (event) => {
      audio.playing = true;
      this.onAudioPlaying(event);
    };

    audio.onended = () => {
      audio.playing = false;
      this.currentAudioIndex++;
      if (this.currentAudioIndex >= this.audios.length) {
        this.audiosEnded();
        return;
      }

      if (this.audios[this.currentAudioIndex]) {
        this.playAudio();
      }
    };
    this.audios.push(audio);
  }

  synthesize(text) {
    this.onFinishLoading(false);
    // eslint-disable-next-line no-console
    console.log('synthesizeText');
    return this.clearAudios()
      .then(() => {
        // this.ws = new WsService();
        this.ws.onFinish = (loaded) => {
          this.onFinishLoading(loaded);
        };
        this.ws.onReceiveData = (audio) => {
          const hasAudio = this.audios[this.currentAudioIndex];
          this.appendAudio(audio);
          if (!hasAudio && this.play) {
            this.playAudio();
          }
          if (this.options.play || this.audios.length === 1) {
            return this.playAudio();
          }
        };
        return this.ws.sendSocket(text, this.options);
      })
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  }

  // eslint-disable-next-line class-methods-use-this
  hasVariable(fileName) {
    return fileName.indexOf('__') !== -1;
  }

  // eslint-disable-next-line class-methods-use-this
  extractVariable(fileName, content) {
    // eslint-disable-next-line security/detect-unsafe-regex
    const variables = fileName.match(/(?<=__)(.+?)(?=__)|(?<=__)(.+?)((?=.wav)|(?=.mp3))/g);
    const variable = content.find(x => x.id === variables[0]).value;
    if (variables.length > 1) {
      return getNameDeclension(variables[2], variable, variables[1]);
    }
    return variable;
  }

  synthesizeVariable({
    fileName,
    content,
  }) {
    return new Promise((resolve) => {
      // this.ws.onFinish = (loaded) => {
      //   this.onFinishLoading(loaded);
      // };
      this.ws.onReceiveData = (audio) => {
        this.appendAudio(audio);
        (() => resolve())();
      };

      const variable = this.extractVariable(fileName, content);

      this.ws.sendSocket(variable, this.options);
    });
  }

  processBlob(blob, currentTime = 0.002) {
    const blobUrl = URL.createObjectURL(blob);
    this.audioUrls.push(blobUrl);

    const audio = new Audio(blobUrl);
    audio.onloadeddata = () => {
      audio.currentTime = currentTime;
    };

    audio.onpause = (event) => {
      audio.playing = false;
      this.onAudioPaused(event);
    };

    audio.onplay = (event) => {
      audio.playing = true;
      this.onAudioPlaying(event);
    };

    audio.onended = () => {
      audio.playing = false;
      this.currentAudioIndex++;
      if (this.currentAudioIndex >= this.audios.length) {
        this.audiosEnded();
        return;
      }

      if (this.audios[this.currentAudioIndex]) {
        this.playAudio();
      }
    };
    this.audios.push(audio);
  }

  loadAudios({ dir, lang, gender, files, audioPrefix, content }) {
    const filtered = files.filter(x => x.fileName.indexOf(`${audioPrefix}_`) !== -1); // фильтруем по странице
    (async () => {
      for (let i = 0; i < filtered.length; i++) {
        const { fileName } = filtered[i];
        // eslint-disable-next-line no-await-in-loop
        await fetchAudio({
          dir,
          lang,
          gender,
          fileName,
        })
          .then((blob) => {
            this.processBlob(blob, (i === 0 ? 0 : 0.16));
            if (this.hasVariable(fileName)) {
              // return this.synthesizeVariable({
              //   fileName,
              //   content,
              // });
              const variable = this.extractVariable(fileName, content);
              return fetchVariable(variable)
                .then(variableBlob => this.processBlob(variableBlob, 0));
            }
            return true;
          });
      }
      this.onFinishLoading(true);
      this.playAudio();
    })();
  }

  initAudio({
    dir,
    lang,
    gender,
    audioPrefix,
    content,
  }) {
    return this.clearAudios()
      .then(() => fetchAudios({
        dir,
        lang,
        gender,
      })
        .then(({ files }) => this.loadAudios({
          dir,
          lang,
          gender,
          files,
          audioPrefix,
          content,
        })));
  }
}

export default TtsStreamService;
