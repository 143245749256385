import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useWindowSize from '@helpers/hooks/useWindowSize';

import Icon from '@app/components/NEW_ui/Icon';
import Text from '@app/components/NEW_ui/Text';
import Button from '@app/components/NEW_ui/Button';
import isGeoRu from '@app/helpers/isGeoRuOrBy';
import isBrowserRussianLanguage from '@app/helpers/isBrowserRussianLanguage';

import { activateSertificate } from '../../../UserMenu/redux/actions';

import './style.scss';

// eslint-disable-next-line no-shadow
const PaymentTicket = ({ user, priceMonth, discountMonth, priceYear, discountYear, buyAction, handleClose /* activateSertificate */ }) => {
  const hotSaleMonth = discountMonth > 0;
  const hotSaleYear = discountYear > 0;
  // const hotSale = hotSaleMonth || hotSaleYear;
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className='pst-paymentTicket'>
      <div className='pst-paymentTicket__lockWrap'>
        <div className='pst-paymentTicket__lockContent'>
          {/* <div className='pst-paymentTicket__lock'> */}
          {/*  <Icon name='Lock' size='sm' template={width > 568 ? 'white' : 'black'} className='pst-paymentTicket__lockIcon' /> */}
          {/*  {hotSale && <div className='pst-paymentTicket__hotSale'>%</div>} */}
          {/* </div> */}
        </div>
      </div>

      <div className='pst-paymentTicket__main'>
        <div className='pst-paymentTicket__title'>
          <Text level='6' weight='800' inline>{isGeoRu(user) || isBrowserRussianLanguage() ? t('paymentTicket.titleRu') : t('paymentTicket.title')}</Text>
        </div>

        <div className='pst-paymentTicket__paymentInfo'>
          <div className='pst-paymentTicket__message'>
            <Text level='6' template='grey' inline>{t('paymentTicket.message')}</Text>
          </div>

          <div className='pst-paymentTicket__prices'>
            <div className='pst-paymentTicket__month'>
              <div className='pst-paymentTicket__unitPrice'>
                <Text level='6' weight='800' inline>{priceMonth}&nbsp;{t('paymentTicket.currency')}&nbsp;/&nbsp;{t('paymentTicket.month')}</Text>
                {hotSaleMonth && <Text level='6' weight='800' template='reddish-pink' inline>(-{discountMonth}%)</Text>}
              </div>

              <div className='pst-paymentTicket__payBtnWrap'>
                <Button template='withoutBackground' onClick={() => buyAction(1)} className='pst-paymentTicket__payBtn'>{t('paymentTicket.buyBtn')}</Button>
              </div>
            </div>
            <div className='pst-paymentTicket__year'>
              <div className='pst-paymentTicket__unitPrice'>
                <Text level='6' weight='800' inline>{priceYear}&nbsp;{t('paymentTicket.currency')}&nbsp;/&nbsp;{t('paymentTicket.year')}</Text>
                {hotSaleYear && <Text level='6' weight='800' template='reddish-pink' inline>(-{discountYear}%)</Text>}
              </div>

              <div className='pst-paymentTicket__payBtnWrap'>
                <Button template='withoutBackground' onClick={() => buyAction(12)} className='pst-paymentTicket__payBtn'>{t('paymentTicket.buyBtn')}</Button>
              </div>
            </div>
            {/* <div className='pst-paymentTicket__sertificat'>
              <div className='pst-paymentTicket__unitPrice'>
                <Text level='6' weight='800' inline>{t('paymentTicket.sertificate')}</Text>
              </div>

              <div className='pst-paymentTicket__payBtnWrap'>
                <Button template='withoutBackground' onClick={activateSertificate} className='pst-paymentTicket__payBtn'>{t('paymentTicket.activate')}</Button>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <Button template='select-white' classNames='pst-paymentTicket__closeBtn' onClick={handleClose}>
        <Icon name='Close' template={width > 568 ? 'gray' : null} alternative={width > 568} size='sm' />
      </Button>
    </div>
  );
};

PaymentTicket.defaultProps = {
  user: false,
  priceMonth: 0,
  discountMonth: 0,
  priceYear: 0,
  discountYear: 0,
  buyAction: () => { },
};

PaymentTicket.propTypes = {
  user: PropTypes.object,
  priceMonth: PropTypes.number,
  discountMonth: PropTypes.number,
  priceYear: PropTypes.number,
  discountYear: PropTypes.number,
  buyAction: PropTypes.func,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  activateSertificate,
}, dispatch);

export default connect(null, mapDispatchToProps)(PaymentTicket);
