import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { loadUser } from '@app/pages/Auth/redux/actions';
import getQueryParam from '@app/helpers/parseQueryUrl';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';
import CookiesService, { SHOW_PAYMENT, PAYMENT_PERIOD, LOGIN_FACEBOOK, LOGIN_VKONTAKTE, LOGIN_GOOGLE, LOGIN_ODNOKLASSNIKI } from '@app/services/CookiesService';

export default function (WrappedComponent) {
  const mapStateToProps = ({ auth }) => ({ user: auth.user });
  const mapDispatchToProps = dispatch => bindActionCreators({ loadUser }, dispatch);

  return connect(mapStateToProps, mapDispatchToProps)(class WithAuth extends Component {
    static propTypes = {
      user: PropTypes.object,
      location: PropTypes.object,
    };

    constructor() {
      super();

      this.state = { loading: true };
    }

    async componentDidMount() {
      const { user, loadUser: fetch } = this.props;

      try {
        if (!user) {
          await fetch();
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.setState({ loading: false });
      }
    }

    // eslint-disable-next-line class-methods-use-this
    renderLoader() {
      // loader here
      return <div />;
    }

    render() {
      const { user, location } = this.props;
      const { loading } = this.state;

      if (loading) return this.renderLoader();

      const isAuthChildComponent = location.pathname.includes('auth');
      const isPublicRoute = location.pathname.includes('public');
      const provider = getQueryParam('provider');
      const action = getQueryParam('action');
      const showPayment = getQueryParam('showPayment');
      const paymentPeriod = getQueryParam('period');

      if (showPayment && isAuthChildComponent) {
        CookiesService.setCookie(SHOW_PAYMENT, true, { domain: 'myskazka.com' });
        CookiesService.setCookie(PAYMENT_PERIOD, paymentPeriod, { domain: 'myskazka.com' });
      }

      if (provider && action) {
        MixpanelService.setUser(user);
        MixpanelService.track(`${mixpanelMap.IDENTIFY_USER} ${action}-${provider}`, { provider, action });

        if (!CookiesService.getCookie(LOGIN_FACEBOOK) && provider === 'facebook') {
          CookiesService.setCookie(LOGIN_FACEBOOK);
          window.dataLayer.push({ event: 'entrance' });
        }

        if (!CookiesService.getCookie(LOGIN_VKONTAKTE) && provider === 'vkontakte') {
          CookiesService.setCookie(LOGIN_VKONTAKTE);
          window.dataLayer.push({ event: 'entrance' });
        }

        if (!CookiesService.getCookie(LOGIN_ODNOKLASSNIKI) && provider === 'odnoklassniki') {
          CookiesService.setCookie(LOGIN_ODNOKLASSNIKI);
          window.dataLayer.push({ event: 'entrance' });
        }

        if (!CookiesService.getCookie(LOGIN_GOOGLE) && provider === 'google') {
          CookiesService.setCookie(LOGIN_GOOGLE);
          window.dataLayer.push({ event: 'entrance' });
        }
      }

      if (!user && !isAuthChildComponent && !isPublicRoute) return <Redirect to="/auth/login?logout=true" />;
      if (user && isAuthChildComponent) return <Redirect to="/" />;
      return <WrappedComponent {...this.props} />;
    }
  });
}
