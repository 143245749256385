import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import withAuth from '@helpers/hocs/withAuth';
import setMetatags from '@helpers/setMetatags';
import routerNames from '@helpers/routerNames';
import getQueryParam from '@app/helpers/parseQueryUrl';
import setLanguage from '@helpers/setLanguage';

import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';
import addObserverPolyfill from '@helpers/addObserverPolyfill';

import './assets/scss/index.scss';
import '@helpers/cancelScaling';

const PROJECT_NAME = 'Myskazka';

const correctVhOnMobile = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
correctVhOnMobile();
setTimeout(correctVhOnMobile, 400);
setTimeout(correctVhOnMobile, 700);
setTimeout(correctVhOnMobile, 1100);

const mixpanelEndSession = () => {
  MixpanelService.track(mixpanelMap.END_USER_SESSION, { endSessionDate: new Date().toString() }, { transport: 'sendBeacon' });
};

window.addEventListener('unload', mixpanelEndSession);
window.addEventListener('resize', correctVhOnMobile);
addObserverPolyfill();

// Get navigator info START

const SPREADSHEET_ID = '1KJlzeu3kMmThO_K73blFLTCmm2MpcKoXMj1z14AbupI';
const SHEET_ID = '85836017';
const CLIENT_EMAIL = 'myskazka@myskazka-feedbacks-285614.iam.gserviceaccount.com';
const PRIVATE_KEY = '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQClYnn8MoA+UAek\nf0XK/h4owZhFJsDQ3aFT3hrKy6PYaB2Tav3Ttcj3upCzCRSxyuBMzJ8X7fWrLTo/\nVO4/EBqBn4+gkG+3REShgkiQSih9BXU88wp6xs/GQcOzcWonZYzhmYatNTfcSF0L\naRTqXfhhCYH21b78Xmk2NOjJb51FCzB7yxvUVuR1LKCI4uemRunU96VhJ7CybkBA\nKaFsrrT7DJH2w32ruujX3D6ACHxQMbTPDM6mV7nT5gIGFvQONwjazjyTcvnox3NL\nE0sxrlmoO1YALY4crqOye3b09HDCf6Ddnk/lGdalf5kx8DhHasALRsWpAA4jV0aF\nRy0Xp9wJAgMBAAECggEAGN3CXRjDvvq99236cq+8HaEkEaumvJDCP6+qpBizwSdA\nYSiqUvFnbtXyiTTBk4zfJjTy68Bn2m2xrxGIQ9uaPKieygPFhE7jqcwCuI0p4shW\nDQy24+huF49OSzXvgc5rPQVeIj9Y74pC0SMi1a082ew5ygDv1xaj55ZTdOwO/7EV\n2rytz3tRo3/Iwfgu6WY13luh6godcJ6JYzkShezfxQUEIfhxIGNL6Za9B/EhYyaP\n0G7x3AsVdEq5hqHMKbB/6TUipSraKopfqbgXmJruCi2svJABpC1B1b7Fuug2xt9p\nYuJ3sTf0StQ9xvdBn35TGZigJSn4qJzkJc9/rHQnHQKBgQDTPlqK3ixDg9AwzscI\n6cQAApScvyQOmXWEw3r08905tXl7Uf6kxf7I6Twl86K+XUU8kUIA7ihKDBKp1HTh\n6keo/MhsvdC2+egq0R0/dWxQTEXr+h9ADpe2pXRgSIt4jl8ElTSZao9fTPJCHERv\ni3zmKhZV88m/us+9uDbUx3IFDQKBgQDIbMfj4QJMVLDiVLro0imZraoF/CT3TJnT\n0Sg9k5mMUUBqkNPPJyDfqsz/x0Hf5b1+CqGFNAbX0GTw4BSBQcnOCvrVKLYYUNXI\nOv330Q3icDynRZBmlp3q41O65q89lLJMojQWkt4HyylszGtvmnY3q1pV9EaEEVbi\n+o0K7UAr7QKBgDLrA74XYYoAms04+mbemhz6gkHqHNHcEaSnzdp/caK4aIjeMcgb\n5ZceCR6IxICoUMkRplw9C78Ve+YtzOKrqwxDqjfz5PxF7r1rXGZwcmREjlIcHy48\nljszVILk2aF8h9C9+kjEk7x/+qEO2Twd3kRIfsvHEKlDaVGstqLBVG19AoGBAJ9Z\nrcvGeFdL9p7ckwbNIXooE0hS4ukkJCG+5+mdUrW3XCneIClRiNeMKxUpzXxAYtq1\nhRos9UvdSoQhBOyH6/U/0ZJsnerTSkW6cXX45rskVXUA4ABQvBmmyEmPY8DoWqKH\nTDauao1zv+na1YiUHG3CdT5lyzLxvk0Uu9L2MtE5AoGAQefzy4GUrBMNpSmJjyAF\nJROiRTVo/PJgFkwskvi7w0OfJw6YUN1d+hWenaYhcCGQia4DLhUflvfNUw919g5H\nA2Nzaniathwo6KpPMxLseoebssLwHmpkphIwI5EDDX3Ch5Lq3zJZS7TE5QBJygh+\nNom+hnwtUUXBIs9fMtYRN8g=\n-----END PRIVATE KEY-----\n';

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

const appendSpreadsheet = async (row) => {
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });

    await doc.loadInfo();
    const sheet = doc.sheetsById[SHEET_ID];
    await sheet.addRow(row);
  } catch (e) {
    window.console.error('Error: ', e);
  }
};

appendSpreadsheet({
  appCodeName: window.navigator.appCodeName,
  appName: window.navigator.appName,
  appVersion: window.navigator.appVersion,
  platform: window.navigator.platform,
  product: window.navigator.product,
  userAgent: window.navigator.userAgent,
  vendor: window.navigator.vendor,
});

// get navigator info END

export default withTranslation()(withAuth(({ route, history, i18n }) => {
  const { pathname } = useLocation();
  const helmet = {
    titleTemplate: `%s | ${PROJECT_NAME}`,
    titleAttributes: {
      itemprop: 'name',
      lang: process.env.REACT_APP_DEFAULT_LANGUAGE,
    },
    meta: setMetatags(window),
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const lang = getQueryParam('lang');
    if (lang) {
      setLanguage(i18n, lang);
    }
  }, [history, i18n, pathname]);

  return (
    <React.Fragment>
      <Helmet {...helmet} title={routerNames(pathname)} />
      {renderRoutes(route.routes)}
    </React.Fragment>
  );
}));
