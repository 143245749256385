import React, { useState, useEffect } from 'react';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CookiesService, { FEEDBACK, FEEDBACK_VIEWER, SEND_VIEWER_FEEDBACK } from '@app/services/CookiesService';
import Icon from '@app/components/NEW_ui/Icon';
import Button from '@app/components/NEW_ui/Button';
import Sharings from '@app/components/NEW_ui/Sharings';

import { getFormattedDate } from '@helpers/dateFormatter';
import MixpanelService from '@app/services/MixpanelService';
import mixpanelMap from '@app/mixpanelMap';

import { fetchRoistat } from '@app/services/RoistatService';

import './style.scss';

// Инструкция по созданию google API тут https://dev.to/calvinpak/how-to-read-write-google-sheets-with-react-193l

const SPREADSHEET_ID = '15ARAjN7aYeNGpXa15NAMM_tzqzmJMgKKjGH8ymrJDlA';
const SHEET_ID = '85836017';
const CLIENT_EMAIL = 'myskazka-feedback@myskazka-285615.iam.gserviceaccount.com';
const PRIVATE_KEY = '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCtqjMGMi3D9R5U\n9ggf2tl/TVbQNx8TEzmrIzQCT8wVdpvBzclckiABLuhCpB4KvJSqDLmRP+xHpu+i\ntQtLrzynLtphCIK4Jcg/rEXKQpFRAc8eSVIs9qMKNYZlM1H0HFjyGCxQN60X9NEk\n8V9KGsWZ9/HclXDOhUMG8tod1yb3EFNQ/9jBkyksjiDglgJ04uzXWY18JbxP6BDR\noGdg/KVDGpu/GK/5ucARlP4/Ts+7XzwFBm/Y/3T2nZg9e1hnnglNHBc/wO2GOc1b\nmqbPtLn7QPJ0HT86O5yRMyEoMX4cGxg1elxBvbWpIGud+mCapooQ7fCwl1/Ozptw\nhff/ZYk/AgMBAAECggEAUHCJedE8SYv8JLZhRQAFxmV4VLFzf05kY9TGfluf2EE8\nHCTJpEs679FeW5/RGoAHDE0glxrYwsecL+1T8haVLYsSM+fRRKKbgoK1QN5cmP0R\nhqHHonQLSOtJ+Q4wY7XNrTVtMSrYzkPjrAzxak3EQXELYnUF72LijRFn3nKN5dmy\nHT7Z1kX4yQdLgz5p9xkDYqtYD/0oA86aO2nkjvPRw6hXToqHsdPnxuUSqaFmlkqR\nev/LeuTT5hd/+tuwTUnzyivLXl9MRsdMGv5/9x6XpRG8Cg0Bm8kF/jv0DibwDD8J\nOksfkDdJ88NjVCk7n/bwu4elAZkei8D6HH0eFSoDgQKBgQDjOWe+LBwgxue7q/nH\nRCqkP7hWp3Sx5t1eXMHDAsE1igzkGXh+kSml4LxawaDC/vw4qo1y341w/eXAw6Zy\nVNoAWboADsZzQ7oltb84mOxZCvJpt/H0TBCQYYL4DCstD31L074EW7DjnmMWaRin\nWtmZtHZxNqYzA7aYyvrUlJHa0QKBgQDDqGblJChGK7Ip4aT92dUJcl2OcO7Fp35I\nc0h/HqRoVvlRT3TCfcAoiZc3lwNKkjXym82Ak/lLj+onZz9eBQ0XdHMC94rVJxxr\n9G7i6qjQobJ5ywlcMepmbaxLs6nEsWj1wtEBjDHolWef9niLu7bN3X4SRaNRYuV/\nCq6Aq34HDwKBgQCSUpY+/bLDXLM/xHhd3MTE7o+rpbxdD7lAGeVgyjZXXuX8QXa5\nsosqWbWRkB0RVRhg+VuLPPCdcbLxVkyqVX/pN96ySNF8yoFl5UsVjEyu7oC6mYzC\n7/S+tTyctWFcbBwYp2s27UDU41sVxGnu4287Lt4S+8k9EsgUTqoGGQJv8QKBgAou\n8qQMfLgiQBSj9OVteAQU2r+2KwdIj28P6P8MxjvUAwfzXb1dtCmn96HiDz7LW1l0\nOH7vtLL1G5fPbOHk22iUHARUg/pSHc2UnEzdL8okEFj8c1FEG9IW9Te/cx+UqETa\nTeyJsnNLi2gs+Ns0+u++Dni+/SAYSJ+TG8AArP+LAoGAemy9zu6HPLkSLQVUbrQq\n6iD//refq1zGcdPpTUdqoC7LpOcrjIgn8qsEk8tqkZRFJcGGWtIap5sng3jYCmCM\nvF4y/IEDsy14ytHNzbQBQAO/0rLVG5HYxAKjSeUz+yM3xd58yd1+VEXvoDuGGg5E\nQPnVupzPSwz5Sr16Bi6rpAI=\n-----END PRIVATE KEY-----\n';

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

const Feedback = ({ mod, user, externalStatus, onCloseCallback }) => {
  const defaultFormData = {
    rating: '',
    feedbackText: '',
  };

  const { t } = useTranslation();
  const [status, setStatus] = useState('inactive');
  const [formData, setFormData] = useState(defaultFormData);

  const isInactive = status === 'inactive';
  const isFeedback = status === 'feedback';
  const isSharings = status === 'sharings';
  const isModDashboard = mod === 'dashboard';
  const isModViewer = mod === 'viewer';

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });

      await doc.loadInfo();
      const sheet = doc.sheetsById[SHEET_ID];
      await sheet.addRow(row);
    } catch (e) {
      window.console.error('Error: ', e);
    }
  };

  useEffect(() => {
    if (isModDashboard) {
      const shownFeedback = CookiesService.getCookie(FEEDBACK);

      if (!shownFeedback) {
        setTimeout(() => {
          setStatus('feedback');
          CookiesService.setCookie(FEEDBACK);
        }, 5000);
      }
    }
  }, [isModDashboard]);

  useEffect(() => {
    if (externalStatus) setStatus(externalStatus);
  }, [externalStatus]);

  const openPopupHandler = () => {
    setStatus('feedback');
    MixpanelService.track(mixpanelMap.OPEN_FEEDBACK_FORM, { userEmail: user.email, userName: user.name });
  };

  const postFormData = () => {
    if (formData.rating !== '' || formData.feedbackText !== '') {
      appendSpreadsheet({
        Date: getFormattedDate(new Date(), { withTime: true }),
        Email: user.email,
        Name: user.name,
        Rating: formData.rating,
        Feedback: formData.feedbackText,
      });

      setStatus('sharings');
      setFormData(defaultFormData);

      CookiesService.setCookie(SEND_VIEWER_FEEDBACK);

      fetchRoistat('review', {
        userEmail: user.email,
        rating: formData.rating,
      });

      MixpanelService.track(`${mixpanelMap.SUBMIT_FEEDBACK_FORM} - ${formData.rating}`, {
        userEmail: user.email,
        userName: user.name,
        Rating: formData.rating,
        Feedback: formData.feedbackText,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    postFormData();
  };

  const feedbackButtonHandler = (e, rating) => {
    e.preventDefault();

    const newFormData = { ...formData };
    newFormData.rating = rating;

    setFormData(newFormData);
    MixpanelService.track(`${mixpanelMap.FEEDBACK_FORM_RATING} - ${rating}`, { userEmail: user.email, userName: user.name });
  };

  const feedbackTextHandler = (e) => {
    const newFormData = { ...formData };
    newFormData.feedbackText = e.target.value;

    setFormData(newFormData);
  };

  const onClose = () => {
    setStatus('inactive');
    postFormData();

    if (isModViewer) {
      CookiesService.setCookie(FEEDBACK_VIEWER);
    }
    if (onCloseCallback) {
      onCloseCallback();
    }

    MixpanelService.track(mixpanelMap.CLOSE_FEEDBACK_FORM, { userEmail: user.email, userName: user.name });
  };

  return (
    <div
      className={`
        pst-feedbackDashboard
        ${mod ? `pst-feedbackDashboard--${mod}` : ''}
        ${status && isModViewer ? `is-${status}` : ''}
      `}
    >
      {isInactive && !isModViewer && (
        <button
          className={`pst-feedbackDashboard__openButton pst-feedbackDashboard__openButton--${mod}`}
          onClick={openPopupHandler}
        >
          {t('feedback.openButton')}
        </button>
      )}

      {(isFeedback || isSharings) && (
        <div className='pst-feedbackDashboard__popup'>
          <button
            className='pst-feedbackDashboard__closeButton'
            onClick={onClose}
          >
            <Icon
              name='Close'
              size='sm'
              alternative
              template='gray'
            />
          </button>
          <h3 className='pst-feedbackDashboard__title'>
            {isFeedback && t('feedback.title')}
            {isSharings && t('feedback.sharingsTitle')}
          </h3>
          <p className='pst-feedbackDashboard__subtitle'>
            {isFeedback && t('feedback.subtitle')}
            {isSharings && t('feedback.sharingsSubtitle')}
          </p>

          {isFeedback && (
            <form className='pst-feedbackDashboard__form'>
              <div className='pst-feedbackDashboard__buttons'>
                <Button
                  classNames='pst-feedbackDashboard__feedbackButton'
                  onClick={e => feedbackButtonHandler(e, 'like')}
                  template='bigGreen'
                  active={formData.rating === 'like'}
                >
                  <Icon name='Like' size='sm' />
                  <span className='pst-feedbackDashboard__feedbackButtonText'>
                    {t('feedback.like')}
                  </span>
                </Button>
                <Button
                  classNames='pst-feedbackDashboard__feedbackButton'
                  onClick={e => feedbackButtonHandler(e, 'dislike')}
                  template='bigRed'
                  active={formData.rating === 'dislike'}
                >
                  <Icon name='Dislike' size='sm' />
                  <span className='pst-feedbackDashboard__feedbackButtonText'>
                    {t('feedback.dislike')}
                  </span>
                </Button>
              </div>

              <textarea
                className='pst-feedbackDashboard__textarea'
                value={formData.feedbackText}
                onChange={feedbackTextHandler}
                placeholder={t('feedback.placeholder')}
              />

              <Button
                classNames='pst-feedbackDashboard__submitButton'
                onClick={submitHandler}
                template='bigPurple'
              >
                {t('feedback.submitButton')}
              </Button>
            </form>
          )}

          {isSharings && (
            <div className='pst-feedbackDashboard__sharingsWrapper'>
              <Sharings user={user} useMixpanel />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(Feedback);
