import readArchive from '@helpers/readArchive';
import http from '@app/services/HttpService';

export const fetchPresentationData = pastyllaId => http.get(`/account/pastylla/${pastyllaId}`, {
  headers: {
    'Content-Type': 'application/json; application/octet-stream',
  },
  responseType: 'blob',
})
  .then(({ data }) => readArchive(data));

export const createPresentation = data => http({
  url: '/account/pastylla',
  method: 'POST',
  headers: {
    'content-type': 'multipart/form-data',
  },
  data,
});

export const updatePresentation = (body, id) => http.put(`/account/pastylla/${id}`, body, {
  headers: {
    'content-type': 'multipart/form-data',
  },
});

export const deletaPresentation = (_id, token) => http.delete(`/account/pastylla/${_id}`, null, {
  headers: {
    Token: `${token}`,
  },
});
