import { useState, useEffect } from 'react';
import useScreenOrientation from 'react-hook-screen-orientation';

const useWindowSize = () => {
  const screenOrientation = useScreenOrientation();
  let timerId;

  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      clearTimeout(timerId);
      setWindowSize(getSize());
      timerId = setTimeout(() => setWindowSize(getSize()), 150);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    setWindowSize(getSize());
  }, [screenOrientation]);

  // eslint-disable-next-line no-console
  console.log(`Rendered at ${windowSize.width} x ${windowSize.height}`);

  return windowSize;
};

export default useWindowSize;
