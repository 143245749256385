import axios from 'axios';

export const baseURL = process.env.REACT_APP_API_URL;

/**
 * @type {AxiosInstance}
 */
export default axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
});

export const apiV3 = axios.create({
  baseURL: '/api/v3',
  headers: { 'Content-Type': 'application/json' },
});

export const apiPochemusha = axios.create({
  baseURL: '/api/pochemusha',
  headers: { 'Content-Type': 'application/json' },
});
