import http from '@app/services/HttpService';

import { analyzeText, translateText } from './DesignService';

/**
 * Fetch topic list
 * @return {Promise<>}
 */
export const fetchTopicList = () => http.get('/account/topic').then(({ data }) => data);

/**
 * Fetch account data
 * @return {Promise<>}
 */
export const fetchAccountData = () => http.get('/account/pastylla/').then(({ data }) => data);

/**
 * Fetch images by query
 * @param {String} _id
 * @return {Promise<>}
 */
export const fetchImages = query => http.get(`stock/unsplash?query=${query}`)
  .then(({ data }) => data);

export const fetchImagesForResponse = (query, callback) => analyzeText(query)
  .then(strings => translateText(strings))
  .then(translated => fetchImages(translated))
  .then((res) => {
    if (callback) callback('img');
    return res;
  });
