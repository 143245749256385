import deleteCookie from '@helpers/deleteCookie';
import CookiesService, { HIDE_LOCK_SCREEN_AFTER_LOGIN } from '@app/services/CookiesService';
import http from '@app/services/HttpService';

export const fetchUser = () => http.get('/auth').then(({ data }) => data);
/**
 * Login user by email & password
 * @param {String} email
 * @param {String} password
 * @return {Promise<Object|Undefined>}
 */
export const loginUser = ({ email, password }) => http.post('/auth/login', {
  email,
  password,
}).then(({ data }) => data);

/**
 * Register user by email & password
 * @param {String} email
 * @param {String} password
 * @return {Promise<Object|Undefined>}
 */
export const registerUser = ({ email, password, lang }) => http.post('/auth/signup', {
  email,
  password,
  lang,
}).then(({ data }) => data);

/**
 * Restore user password by email
 * @param {String} email
 * @return {Promise<String|Undefined>}
 */
export const restorePassword = ({ email }) => http.post('/auth/restore', {
  email,
}).then(({ data: { token } }) => token);

/**
 * Check restore token
 * @param {String} token
 * @return {Promise<>}
 */
export const checkRestoreToken = ({ token }) => http.post('/auth/token', {
  token,
}).then(({ data }) => data);

/**
 * Set new password
 * @param {String} token
 * @param {String} password
 * @return {Promise<>}
 */
export const setNewPassword = ({ token, password }) => http.post('/auth/reset', {
  token, password,
}).then(({ data }) => data);

/**
 * Activate user
 * @param {String} activate-code
 * @return {Promise<>}
 */

export const activateUserByCode = code => http.get(`/auth/activate/${code}`)
  .then(({ data }) => data);

/**
 * Logout user
 * @return {Promise<>}
 */
export const logoutUser = async () => {
  CookiesService.deleteCookie(HIDE_LOCK_SCREEN_AFTER_LOGIN);
  await deleteCookie('token', '/');
  window.location = '/auth/login?logout=true';
};

export const deleteUser = () => http.delete('/auth').then(({ data }) => data);
