import React, { Component } from 'react';
import { renderRoutes } from 'react-router-config';
import { Redirect } from 'react-router-dom';
import SplitLayout from '@app/components/NEW_ui/SplitLayout';

import './style.scss';

export default class Auth extends Component {
  render() {
    const { route } = this.props;
    const { location } = this.props;

    if (location.pathname === '/auth') return <Redirect to="/auth/login?logout=true" />;

    return (
      <SplitLayout
        isLeftSectionHideTablet
        left={
          <div className='pst-auth'>
            <video className='pst-auth__video' autoPlay loop muted playsInline>
              <source src='https://app.myskazka.com/img/tea.mp4' type='video/mp4' />
            </video>
            <div className='pst-auth__loader' />
          </div>
        }
        right={renderRoutes(route.routes)} />
    );
  }
}
