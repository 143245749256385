import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import Ripple from '@intereact/ripple';

import Icon from '@app/components/NEW_ui/Icon';
import Text from '@app/components/NEW_ui/Text';
import Button from '@app/components/NEW_ui/Button';
import RoundButton from '@app/components/NEW_ui/RoundButton';
import useWindowSize from '@helpers/hooks/useWindowSize';
import isGeoRu from '@app/helpers/isGeoRuOrBy';
import isBrowserRussianLanguage from '@app/helpers/isBrowserRussianLanguage';
import { activateSertificate } from '../UserMenu/redux/actions';

import './style.scss';

// eslint-disable-next-line no-shadow
const LockScreen = ({ user, fixed, priceMonth, /* discountMonth, */ priceYear, discountYear, onClick, onClose /* activateSertificate */ }) => {
  // const hotSaleMonth = discountMonth > 0;
  const hotSaleYear = discountYear > 0;
  const { t } = useTranslation();
  const exitBtn = useRef(null);
  const { width } = useWindowSize();
  const isMobileScreen = width <= 820;

  return (
    <div className={`pst-lockScreen ${fixed ? 'pst-lockScreen--fixed' : ''}`}>
      {onClose && <div className={'pst-viewerSidebar__exit pst-viewerSidebar__exit--show'} ref={exitBtn} >
        <RoundButton
          name="Close"
          size={isMobileScreen ? 'sm' : 'md'}
          onClick={onClose}
        />
      </div>}
      <div className='pst-lockScreen__content'>
        <div className='pst-lockScreen__lockWrap'>
          <Icon name='Lock' template='white' className='pst-lockScreen__lockIcon' />
        </div>

        <div className='pst-lockScreen__main'>
          <div className='pst-lockScreen__title'>
            <Text level='1' weight={900} template='white' inline>{isGeoRu(user) || isBrowserRussianLanguage() ? t('lockScreen.titleRu') : t('lockScreen.title')}</Text>
          </div>

          <div className='pst-lockScreen__message'>
            <Text level='4' inline>
              {/* {t('lockScreen.message')} */}
              <p className='pst-lockScreen__item'>{t('lockScreen.message1')}</p>
              <p className='pst-lockScreen__item'>{t('lockScreen.message2')}</p>
              <p className='pst-lockScreen__item'>{t('lockScreen.message3')}</p>
              <p className='pst-lockScreen__info'>{t('lockScreen.message4')}</p>
            </Text>
          </div>

          {/* <div className="pst-lockScreen__unitPrice"> */}
          {/*  <Text level="6" template="white" weight="800" inline>{priceMonth}&nbsp;{t('lockScreen.currency')}&nbsp;/&nbsp;{t('lockScreen.month')}</Text> */}
          {/*  {hotSaleMonth && <Text level="6" weight="800" template="reddish-pink" inline>&nbsp;(-{discountMonth}%)</Text>} */}
          {/* </div> */}

          {/* <Text level="6" className="pst-lockScreen__orTxt" inline>{t('lockScreen.or')}</Text> */}

          {/* <div className="pst-lockScreen__unitPrice"> */}
          {/*  <Text level="6" template="white" weight="800" inline>{priceYear}&nbsp;{t('lockScreen.currency')}&nbsp;/&nbsp;{t('lockScreen.year')}</Text> */}
          {/*  {hotSaleYear && <Text level="6" weight="800" template="reddish-pink" inline>&nbsp;(-{discountYear}%)</Text>} */}
          {/* </div> */}
        </div>

        <div className='pst-lockScreen__actionsWrapper'>
          <div className='pst-lockScreen__buyButton pst-lockScreen__buyButton--month'>
            {/* {hotSaleMonth && (
              <div className='pst-lockScreen__discountWrapper'>
                <Text level='6' weight='400' template='white' inline>{t('lockScreen.discount')}&nbsp;{discountMonth}</Text>
                <span className='pst-lockScreen__percent'>%</span>
              </div>
            )} */}

            <Ripple color='rgba(255, 255, 255, 0.4)'>
              {ripples => (
                <Button template='select-white' onClick={() => onClick(1)}>
                  {ripples}
                  {/* {t('lockScreen.subscriptionMonth')} */}
                  {priceMonth}&nbsp;{t('lockScreen.currency')}&nbsp;/&nbsp;{t('lockScreen.month')}
                </Button>
              )}
            </Ripple>
          </div>

          <div className='pst-lockScreen__buyButton pst-lockScreen__buyButton--year'>
            {/* {hotSaleYear && (
              <div className='pst-lockScreen__discountWrapper'>
                <Text level='6' weight='400' template='white' inline>{t('lockScreen.discount')}&nbsp;{discountYear}</Text>
                <span className='pst-lockScreen__percent'>%</span>
              </div>
            )} */}

            <Ripple color='rgba(255, 255, 255, 0.4)'>
              {ripples => (
                <Button template='select-white' onClick={() => onClick(12)}>
                  {ripples}
                  {/* {t('lockScreen.subscriptionYear')} */}
                  {priceYear}&nbsp;{t('lockScreen.currency')}&nbsp;/&nbsp;{t('lockScreen.year')}
                  {hotSaleYear && <span className="reddish-pink" inline>&nbsp;(-{discountYear}%)</span>}
                </Button>
              )}
            </Ripple>
          </div>
        </div>

        {/* <div className='pst-lockScreen__buyButton pst-lockScreen__buyButton--sertificate'>
          <Ripple color='rgba(255, 255, 255, 0.4)'>
            {ripples => (
              <Button template='select-white' onClick={activateSertificate}>
                {ripples}
                {t('lockScreen.sertificate')}
              </Button>
            )}
          </Ripple>
        </div> */}
      </div>
    </div>
  );
};

LockScreen.defaultProps = {
  onClick: () => { },
  priceMonth: 0,
  discountMonth: 0,
  priceYear: 0,
  discountYear: 0,
};

LockScreen.propTypes = {
  user: PropTypes.object,
  onClick: PropTypes.func,
  priceMonth: PropTypes.number,
  discountMonth: PropTypes.number,
  priceYear: PropTypes.number,
  discountYear: PropTypes.number,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  activateSertificate,
}, dispatch);

export default connect(null, mapDispatchToProps)(LockScreen);
