import genderMap from '@app/store/constants/genderMap';
import { replaceOtherTags } from './replaceTtsTags';
import getNameDeclension from './getNameDeclension';

export default (contains, content, topic) => {
  const { gender } = topic;
  const detectedGender = genderMap[gender] || 'androgynous';

  let res;

  for (let i = 0; i < contains.length; i++) {
    const item = contains[i];
    let _value = item.value;
    let _template = item.template;

    if (!_value && item.param) {
      const param = content.find(_i => _i.id === item.param);

      if (param) _value = param.value;
    }

    if (_template && !_value && !item.param) {
      _template = replaceOtherTags(_template);
      const variables = _template.match(/%{(.*?)}%/g);

      if (variables && variables.length) {
        variables.forEach((matchValue) => {
          const parsedParam = matchValue.replace(/%{|}%/g, '');

          const hasCase = parsedParam.split(/__/);
          if (hasCase.length > 1) {
            const paramValue = hasCase[0];
            const caseName = hasCase[1];
            const parsedGender = hasCase[2];

            const contValue = content.find(({ id }) => id === paramValue);
            const replacement = contValue ? contValue.value : '';
            const finalGender = parsedGender || detectedGender;

            const nameDeclension = getNameDeclension(finalGender, replacement, caseName);

            _template = _template.replace(new RegExp(`%{${parsedParam}}%`, 'g'), nameDeclension);
          } else {
            const contValue = content.find(({ id }) => id === parsedParam);
            const replacement = contValue ? contValue.value : '';
            _template = _template.replace(new RegExp(`%{${parsedParam}}%`, 'g'), replacement);
          }
        });
      }

      res = _template;
    }
  }

  return res;
};
